.cardinfo {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-width: 550px;
  width: fit-content;
  max-width: 650px;
  height: fit-content;
}

.cardinfo-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cardinfo-box-title {
  display: flex;
  gap: 10px;
  align-items: center;
}

.cardinfo-box-title p {
  font-weight: bold;
  font-size: 1.2rem;
}

.cardinfo-box-title svg {
  height: 18px;
  width: 18px;
}

.cardinfo-box-labels {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.cardinfo-box-labels label {
  border-radius: 40px;
  background-color: gray;
  color: #fff;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.cardinfo-box-labels label svg {
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.cardinfo-box ul {
  display: flex;
  gap: 15px;
  margin-left: 20px;
}
.cardinfo-box ul li {
  list-style: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}

.cardinfo-box ul .li-active {
  box-shadow: 0 0 0 3px yellow; 
  border: 2px solid red;
  padding: 8px;
}

.cardinfo-box-progress-bar {
  width: 100%;
  border-radius: 30px;
  height: 10px;
  border: 1px solid #ccc;
}

.cardinfo-box-progress {
  height: 100%;
  border-radius: 30px;
  background-color: skyblue;
  width: 0;
  transition: 200ms;
}

.cardinfo-box-task-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cardinfo-box-task-checkbox {
  display: flex;
  gap: 10px;
}

.cardinfo-box-task-checkbox input,
.cardinfo-box-task-checkbox svg {
  height: 18px;
  width: 18px;
  outline: none;
  cursor: pointer;
}

.cardinfo-box-task-checkbox p {
  flex: 1;
  line-height: 18px;
}

.cardinfo-box-task-checkbox .completed {
  text-decoration-line: line-through;
}

.cardinfo-box input[type="date"] {
  width: fit-content;
  border: 2px solid #ddd;
  border-radius: 5px;
  outline: none;
  font-size: 1rem;
  padding: 10px;
}
